<script lang="ts" setup>
import { computed } from 'vue'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'
import useI18n from '~/src/UserInterface/App/composables/Translation/useI18n'

defineProps<{ dropup?: boolean }>()

const { navigateTo } = useNavigation()
const { locale: currentLocale, localeCodes, localeNames, switchLocalePath } = useI18n()

const locales = computed(() =>
  localeCodes.value.reduce(
    (a: { value: string, label: string }[], locale: string) => {
      a.push({
        value: locale,
        label: localeNames[locale],
      })

      return a
    },
    [] as { value: string, label: string }[],
  ),
)

const switchLocale = async (locale: string) => {
  await navigateTo(switchLocalePath(locale))
}
</script>

<template>
  <ui-dropdown
    variant="link"
    :dropup="dropup"
    no-caret
  >
    <template #button-content>
      <slot name="button-text">
        {{ $t('components.intl.language_select_popover.btn_change') }}
      </slot>
    </template>

    <ui-dropdown-item
      v-for="locale in locales"
      :key="locale.value"
      :active="locale.value === currentLocale"
      :disabled="locale.value === currentLocale"
      @click="switchLocale(locale.value)"
    >
      {{ locale.label }}
    </ui-dropdown-item>
  </ui-dropdown>
</template>
